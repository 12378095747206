import { RefObject, useEffect, useRef } from 'react';

export function useButtonArrowResizer(
  innerReference: RefObject<HTMLAnchorElement>,
  svgReference: RefObject<SVGSVGElement>
) {
  const previousWindowInner = useRef(0);

  useEffect(() => {
    function onResize() {
      if (!innerReference.current || !svgReference.current) return;

      // If the window is growing bigger, we need to reset the svg to determine the container height
      if (window.innerWidth > previousWindowInner.current) {
        const innerFirstChildHeight = innerReference.current.children[0]?.clientHeight;
        svgReference.current.style.height = `${innerFirstChildHeight}px`;
      }

      const containerHeight = innerReference.current.offsetHeight;
      svgReference.current.style.height = `${containerHeight}px`;
      previousWindowInner.current = window.innerWidth;
    }

    // DP: I don't debounce this on purpose, since no real user resizes
    window.addEventListener('resize', onResize);
    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, [innerReference, svgReference]);
}
